import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet molt petit, de 0,5 a 2 cm de diàmetre, en forma de copa inicialment, després pla, amb el marge ondulat i amb una depressió central, i sense peu. L’himeni es troba al interior de la copa i és de color marró rogenc fosc i la superficie externa és més clara. Les espores són el·líptiques, amb berrugues allargades i interconnectades formant un reticle, de 13-18 x 7,5-9 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      